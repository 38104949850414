import React from "react";

import Hero from "../components/common/hero";
import FastestServer from "../components/why/fastestServers";
import CtaSection from "../components/ctaSection";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import { graphql } from "gatsby";
import Support from "../components/why/support";
import { Monitoring } from "../components/why/monitoring";

const Product = ({ location, data: { page } }) => {
  const { lang } = useTranslations();
  return (
    <div>
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo.metaTitle}
        metas={[{ name: "description", content: page.seo.metaDescription }]}
      />
      <Hero title={page?.header?.title} subtitle={page?.header?.subtitle} />
      <FastestServer taggingServer={page.taggingServer} />
      <Monitoring monitoring={page.monitoring} />
      <Support support={page.support} />
      <CtaSection />
    </div>
  );
};

export const query = graphql`
  query StrapiProductPage($locale: String) {
    page: strapiProductPage(locale: { eq: $locale }) {
      seo {
        metaTitle
        metaDescription
      }
      header {
        subtitle
        title
      }
      taggingServer {
        title
        subtitle
        bulletPoint {
          title
          description
          icon
        }
        footer
      }
      monitoring {
        title
        subtitle
        bulletPoint {
          title
          subtitle
          description
          icon
          illustration {
            url
            alternativeText
          }
        }
      }
      support {
        title
        description
        testimony
        author
        illustration {
          url
          alternativeText
        }
        authorPicture {
          url
          alternativeText
        }
        link {
          href
          label
          target
          isExternal
        }
      }
    }
  }
`;

export default Product;
