import React from "react";

import useTranslations from "../useTranslations";
import servers from "../../../config/servers.json";

const FastestServer = ({ taggingServer }) => {
  const { fastestServers } = useTranslations();

  return (
    <div className="bg-gray-50 py-16 lg:py-24">
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-4/6	">
            <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {taggingServer?.title}
            </h2>
            <p className="mt-3 text-lg text-gray-500">
              {taggingServer?.subtitle}
            </p>

            <dl className="mt-10 space-y-10">
              {taggingServer?.bulletPoint?.map((item, index) => (
                <div key={index} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-600 text-white">
                      <div dangerouslySetInnerHTML={{ __html: item.icon }} />
                    </div>
                    <h3 className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.title}
                    </h3>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>

            <p className="mt-6 text-lg text-gray-500">
              {taggingServer?.footer}
            </p>
          </div>

          <div className="flex-1 relative">
            <h3 className="text-center text-lg leading-6 font-medium text-gray-900 mb-2">
              {fastestServers.stats.title}
            </h3>
            <dl className="flex flex-col space-y-2 overflow-hidden ">
              {servers.map((item) => (
                <div
                  key={item.name}
                  className="px-4 py-5 sm:p-6 bg-white rounded-md border border-gray-200"
                >
                  <dt className="text-base font-normal text-gray-900">
                    {item.name}
                  </dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-cyan-600">
                      {item.addingwell}ms
                      <span className="ml-2 text-sm font-medium text-gray-500">
                        vs {item.google}ms
                      </span>
                    </div>

                    {item.google === item.addingwell ? (
                      <div className="bg-gray-200 text-gray-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                        =
                      </div>
                    ) : (
                      <div className="bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                        x{Math.round((item.google / item.addingwell) * 10) / 10}
                      </div>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
            <p className="text-xs text-gray-500 text-center mt-1">
              {fastestServers.stats.footer}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FastestServer;
