import React from "react";

import useTranslations from "../useTranslations";

const Support = ({ support }) => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16 py-16 md:py-20">
          <div className="flex flex-col-reverse md:flex-row items-center gap-12">
            <div className="flex-1">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {support?.title}
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  {support?.description}
                </p>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;
                      {support?.testimony}
                      &rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-6 w-6 rounded-full"
                          src={support?.authorPicture.url}
                          alt={support?.authorPicture.alternativeText}
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        {support.author}{" "}
                        <a
                          href={support.link.href}
                          className="underline"
                          target={support.link.target}
                          rel="noreferrer noopener"
                        >
                          {support.link.label}
                        </a>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="flex-1 flex justify-center mt-12 md:mt-0">
              <img
                className="w-2/3 md:w-full"
                src={support.illustration.url}
                alt={support.illustration.alternativeText}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
