import React, { useState } from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div
      className={classNames(
        className,
        !isActive && "opacity-60 hover:opacity-100"
      )}
      {...props}
    >
      <div
        className={classNames(
          "flex items-center justify-center h-12 w-12 rounded-md text-white",
          isActive ? "bg-cyan-600" : "bg-gray-500"
        )}
      >
        <div dangerouslySetInnerHTML={{ __html: feature.icon }} />
      </div>
      <h3
        className={classNames(
          "mt-6 text-sm font-medium",
          isActive ? "text-cyan-600" : "text-gray-600"
        )}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-medium text-lg text-gray-900">
        {feature.subtitle}
      </p>
      <p className="mt-4 text-md text-gray-500">{feature.description}</p>
    </div>
  );
}

function FeaturesMobile({ features }) {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features?.map((feature) => (
        <div key={feature.title}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div
              style={{ width: "52.75rem" }}
              className="relative mx-auto overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10"
            >
              <img
                className="w-full h-auto"
                src={feature.illustration.url}
                alt={feature.illustration.alternativeText}
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop({ features }) {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8">
            {features?.map((feature, featureIndex) => (
              <Feature
                key={feature.title}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="[&:not(:focus-visible)]:focus:outline-none">
                      <span className="absolute inset-0" />
                      {feature.title}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-10 overflow-hidden rounded-2xl bg-gray-50 px-14 py-16 xl:px-16">
            <div className="-mx-12 flex">
              {features?.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.title}
                  className={classNames(
                    "px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none",
                    featureIndex !== selectedIndex && "opacity-60"
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div
                    style={{ width: "50rem" }}
                    className="overflow-hidden rounded-xl bg-white shadow-lg shadow-gray-900/5 ring-1 ring-gray-100"
                  >
                    <img
                      className="w-full h-auto"
                      src={feature.illustration.url}
                      alt={feature.illustration.alternativeText}
                      placeholder="blurred"
                    />
                  </div>
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-100" />
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  );
}

export function Monitoring({ monitoring }) {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pt-20 pb-14 sm:pb-20 sm:pt-24 lg:pb-24"
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl">
            {monitoring.title}
          </h2>
          <p className="mt-4 text-lg text-gray-500">{monitoring.subtitle}</p>
        </div>
        <FeaturesMobile features={monitoring.bulletPoint} />
        <FeaturesDesktop features={monitoring.bulletPoint} />
      </div>
    </section>
  );
}
